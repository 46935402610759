import React, { Component } from "react";
import Connecting from "./Connection/Connecting";
import { Card, EmptyState } from '@shopify/polaris';
import AppExtension from './App.extension';
import setAppConfig from './store/actions';
import { connect } from "react-redux";
import "./App.css";

const API_KEY = process.env.REACT_APP_API_KEY;
const AUTH_PATH = '/auth';

class App extends Component {

  constructor(props) {
    super(props);

    const searchParams = window.location.search;
    const urlSearchParams = new URLSearchParams(searchParams);
    let query = Object.fromEntries(urlSearchParams.entries());
    let path = window.location.pathname;

    const shopifyData = {
      shopOrigin: query.shop,
      host: query.host,
      apiKey: API_KEY,
      timestamp: query.timestamp,
      hmac: query.hmac,
      code: query.code,
      state: query.state
    };

    this.state = {
      query: query,
      path: path,
      shopifyData: shopifyData,
      searchParams: searchParams
    }
  }

  componentDidMount() {
    this.props.setAppConfig({
      apiKey: this.state.shopifyData.apiKey,
      shopOrigin: this.state.shopifyData.shopOrigin,
      host: this.state.shopifyData.host,
      forceRedirect: true,
    });
  }

  render() {
    return (
      (window.top !== window.self || this.state.query.connectionComplete) ?
        <>
          <AppExtension config={{
            apiKey: this.state.shopifyData.apiKey,
            shopOrigin: this.state.shopifyData.shopOrigin,
            host: this.state.shopifyData.host,
            forceRedirect: true,
          }} />
        </>
        : (this.state.query.shop || this.state.path === AUTH_PATH) ?
          <div>
            <Connecting query={{ shopifyData: this.state.shopifyData, path: this.state.path, searchParams: this.state.searchParams }} />
          </div> :
          <div className="initialization-error">
            <Card sectioned>
              <div style={{display:"flex", justifyContent:"center"}}>
                <EmptyState
                  heading="Initialization Error !"
                  image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                >
                  <p>Please try to install app from shopify store.</p>
                </EmptyState>
              </div>
            </Card>
          </div>
    )
  }
}

const mapStateToProps = state => {
  return { appConfig: state.appConfig };
};

export default connect(mapStateToProps, { setAppConfig })(App);
