import { Component } from 'react';
import './account.connected.css';
import { Page, Layout, Card, Banner, Link, Icon, Avatar, Button, Modal, Stack, TextContainer, Badge, Spinner, Frame, Toast } from '@shopify/polaris';
import { InfoMinor } from '@shopify/polaris-icons';
import { Redirect } from '@shopify/app-bridge/actions';
import instance, { quartzInstance } from '../../api';
import { Accepted, Disconnected, Pending, Rejected } from '../../model/key';
import Mailto from '../../helper/mail/mailto';
import { connect } from 'react-redux';
import appService from '../../services/app.service';
class AccountConnected extends Component {
    constructor(props) {
        super(props)
        this.state = {
            config: props.config,
            modalActive: false,
            disconnectingAccount: false,
            loadingProductStatus: false,
            productInformation: {
                isPublishing: false,
                publishedProducts: 0,
                publishing: 0,
                unPublishedProducts: 0,
                totalProducts: 0
            },
            productSyncMessage: '',
            productSyncInProcess: false,
            errorMessage: null,
            showAccountInformationBanner: false,
            showProductPublishedBanner: true,
            loadingAcceptCommissionUrl : false
        }
        this.toggleDisconnectModal = this.toggleDisconnectModal.bind(this);
        this.disconnectAccount = this.disconnectAccount.bind(this);
        this.showProductWithStatusFilter = this.showProductWithStatusFilter.bind(this);
        this.getPublishingProductStatus = this.getPublishingProductStatus.bind(this);
        this.syncSellerProducts = this.syncSellerProducts.bind(this);
        this.onCloseInfoBanner = this.onCloseInfoBanner.bind(this);
        this.hidePublishingProductBanner = this.hidePublishingProductBanner.bind(this);
        this.onAcceptCommission = this.onAcceptCommission.bind(this);
    }

    hidePublishingProductBanner() {
        this.setState({ showProductPublishedBanner: false });
    }

    componentDidMount() {
        if (this.state.config.sellerStatus === Accepted) {
            this.getPublishingProductStatus();
        }
        try {
            this.setState({ showAccountInformationBanner: true });
        } catch { }
    }

    getPublishingProductStatus() {
        this.setState({ loadingProductStatus: true });
        instance.get('/api/shopify/products/status/get').then(result => {
            if (result.success) {
                let productStatus = { ...this.state.productInformation };
                productStatus.publishedProducts = result?.data?.publishedProducts;
                productStatus.unPublishedProducts = result?.data?.unPublishedProducts;
                productStatus.totalProducts = result?.data?.totalProducts;
                this.setState({ productInformation: productStatus });
                this.setState({ loadingProductStatus: false });
            } else {
                this.setState({ errorMessage: result?.message });
            }
        }).catch(error => {
            this.setState({ errorMessage: error.message });
        });
    }

    disconnectAccount() {
        this.setState({ disconnectingAccount: true });
        instance.post('/api/shopify/vendor/disconnect').then(result => {
            if (result.success && result.data) {
                this.setState({ disconnectingAccount: false });
                this.setState({ modalActive: false });
                this.props.accountDisconnected({ sellerStatus: Disconnected, isShopValid: true });
            }
        }).catch(error => {
            this.setState({ disconnectingAccount: false });
            this.setState({ errorMessage: error.message });
        });
    }

    toggleDisconnectModal(e) {
        let active = !this.state.modalActive;
        this.setState({ modalActive: active });
        this.setState({ disconnectingAccount: false });
    }

    appInstance = appService.getApp(this.props.appConfig.host);
    redirect = Redirect.create(this.appInstance);

    showProductWithStatusFilter(status) {
        this.redirect.dispatch(Redirect.Action.APP, {
            path: `/products?type=${status}`,
            newContext: false,
        });
    }

    syncSellerProducts() {
        this.setState({ productSyncInProcess: true });
        quartzInstance.post('/api/v1/quartz/shopify/product/refresh')
            .then(result => {
                if (result.success) {
                    setTimeout(() => {
                        this.setState({ productSyncMessage: "We've started syncing the products to our Marketplace. Kindly refresh the page after 5 mins to check the status" });
                        this.setState({ productSyncInProcess: false });
                    }, 5000);
                } else {
                    this.setState({ productSyncInProcess: false });
                    this.setState({ errorMessage: result?.message });
                }
            }).catch(error => {
                setTimeout(() => {
                    this.setState({ productSyncMessage: '' });
                    this.setState({ productSyncInProcess: false });
                    this.setState({ errorMessage: error.message });
                }, 1000);
            });
    }

    onCloseInfoBanner = () => {
        this.setState({ showAccountInformationBanner: false })
    }

    onAcceptCommission = () => {
        this.setState({loadingAcceptCommissionUrl : true}); 
        instance.post('/api/shopify/recurring/charge/create')
            .then(response => {
                this.setState({loadingAcceptCommissionUrl : false});
                window.top.location.href = response.data;
            }).catch(error => {                
                this.setState({loadingAcceptCommissionUrl : false});
                this.setState({errorMessage : error?.message});                
            });
    }

    render() {
        return (
            <div style={{ padding: '0 7rem' }}>
                <Frame>
                    {this.state.errorMessage ? <Toast content={this.state.errorMessage} error onDismiss={() => this.setState({ errorMessage: null })} duration={10000} /> : null}
                    <Page fullWidth
                        title="Welcome to Wishlist Marketplace"
                        subtitle={this.state.config.sellerStatus === Rejected ? '' : "Let's get you set up so you can sell your products on the Wishlist Marketplace"}   >
                        <div className={this.state.config.sellerStatus === Accepted ? 'success-banner' : this.state.config.sellerStatus === Pending ? 'info' : 'critical'}>
                            {this.state.showAccountInformationBanner ? <Banner
                                title={this.state.config.sellerStatus === Pending ? 'Wishlist Marketplace is reviewing your store' : this.state.config.sellerStatus === Rejected ? 'You are not ready to sell on the Wishlist Marketplace' : "You've been approved to sell on the Wishlist Marketplace"}
                                status={this.state.config.sellerStatus === Pending ? 'info' : this.state.config.sellerStatus === Rejected ? 'critical' : 'success'}
                                onDismiss={() => { this.onCloseInfoBanner() }}>
                                <p>
                                    {this.state.config.sellerStatus === Pending ? 'It usually takes 1-2 working days to hear back.' : this.state.config.sellerStatus === Rejected ? ' Please write to ' + getEmail(process.env.REACT_APP_SUPPORT) + ' to know more.' : 'Check the status of products being published to the Marketplace.'}
                                </p>
                            </Banner> : <></>}
                        </div>
                        <Layout>
                            <Layout.AnnotatedSection
                                id="appRequirement"
                                title="App Requirement"
                                description={<Requirement />}>
                                <div style={{ paddingTop: '20px' }}>
                                    <Card sectioned>
                                        <div className="requirement-card">
                                            <div className="left">
                                                <img className="account-logo" src="../../../../Wishlist_Logo_Color.png" alt="wishlist logo" />
                                                <div className="about-channel">
                                                    <span className="company-name">Wishlist Marketplace</span>
                                                    <Link url={process.env.REACT_APP_TnC_URL} external>
                                                        <span className="company-terms"> View app terms</span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div><Icon
                                                    source={InfoMinor}
                                                    color="base" /></div>
                                                <Mailto mailto={process.env.REACT_APP_SUPPORT} label="Need help?" />
                                            </div>
                                        </div>
                                        <div>
                                            <p style={{ paddingLeft: '15px', fontSize: '13px' }}>
                                                {
                                                    this.state.config.sellerStatus === Accepted ? 'Your shop meets the Wishlist Marketplace’s basic guidelines'
                                                        : this.state.config.sellerStatus === Rejected ? 'Your shop does not meet the Wishlist Marketplace’s basic guidelines'
                                                            : this.state.config.sellerStatus === Pending ? 'We are checking if your shop meets the Wishlist Marketplace’s basic guidelines' : ''

                                                }
                                            </p>
                                        </div>
                                    </Card>
                                </div>
                            </Layout.AnnotatedSection>
                            <Layout.AnnotatedSection
                                id="account"
                                title="Wishlist Marketplace Account"
                                description="Connect you marketplace Account so you can manage and sync with the Wishlist Marketplace.">
                                <div style={{ paddingTop: '20px' }}>
                                    <Card sectioned>
                                        <div className="account-section">
                                            <div className="account-section-left">
                                                <Avatar customer source={this.props.userConfig?.profileUrl} />
                                                <div>{this.props.userConfig.email}</div>
                                            </div>
                                            <div>
                                                <Button onClick={this.toggleDisconnectModal}>Disconnect</Button>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </Layout.AnnotatedSection>
                            <Layout.AnnotatedSection
                                id="commissions"
                                title="Commissions"
                                description="">
                                <div style={{ paddingTop: '20px' }}>
                                    <Card sectioned>
                                        <div className='commission-flex'>
                                            <span>Charges outlined in your partnership agreement with Wishlist. Will not exceed $100,000 per calendar month</span>
                                            {this.props.userConfig.isRecurringChargeApproved ? <Button disabled>Accepted</Button> : <Button loading={this.state.loadingAcceptCommissionUrl} onClick={this.onAcceptCommission}>Accept</Button>}
                                        </div>
                                    </Card>
                                </div>
                            </Layout.AnnotatedSection>
                            <Layout.AnnotatedSection
                                id="tnc"
                                title="Term and Conditions"
                                description="View Wishlist Marketplace's terms and conditions.">
                                <div style={{ paddingTop: '20px' }}>
                                    <Card sectioned>
                                        <span>You have accepted </span>
                                        <Link url={process.env.REACT_APP_TnC_URL} external>
                                            Marketplace Terms and Conditions
                                        </Link>
                                    </Card>
                                </div>
                            </Layout.AnnotatedSection>
                            {this.state.config.sellerStatus === Accepted ? <><Layout.AnnotatedSection
                                id="publishing"
                                title="Publishing"
                                description="Products that are being synced to your catalog, or have errors preventing their sync, are shown here.">
                                <div style={{ paddingTop: '20px' }}>
                                    <Card>
                                        <Card.Header
                                            actions={[
                                                {
                                                    content: 'Manage Availability',
                                                    onAction: () => this.showProductWithStatusFilter('')
                                                },
                                            ]}
                                            title="Product Status">
                                        </Card.Header>
                                        <Card.Section>
                                            {this.state.loadingProductStatus === true ?
                                                <div className="product-loading">
                                                    <Spinner size="large" />
                                                </div> :
                                                <>
                                                    {this.state.productInformation.isPublishing === true ?
                                                        <div>
                                                            {this.state.showProductPublishedBanner ? <Banner
                                                                title=""
                                                                status="info"
                                                                onDismiss={() => { }}>
                                                                <p>Products publishing to Wishlist Marketplace can take 30 minutes to update. Once your products are successfully published your products will be visible on Wishlist Marketplace.</p>
                                                            </Banner> : ''}
                                                            <div style={{ paddingTop: '2rem' }}>
                                                                <p>{this.state.productInformation.totalProducts} products are available to Wishlist Marketplace</p>
                                                            </div>
                                                        </div> :
                                                        <div>
                                                            {this.state.productInformation.unPublishedProducts > 0 ? <div>
                                                                <Banner
                                                                    title=""
                                                                    action={{ content: 'Add Information', onAction: () => this.showProductWithStatusFilter('unpublished') }}
                                                                    status="critical">
                                                                    <p>
                                                                        <span style={{ fontWeight: 'bold' }}>{this.state.productInformation.unPublishedProducts} {this.state.productInformation.unPublishedProducts > 1 ? 'products' : 'product'} </span><span> require more information before it can be published to the Wishlist Marketplace.</span>
                                                                    </p>
                                                                </Banner>
                                                                <div style={{ paddingTop: '2rem' }}>
                                                                    <p> <span style={{ fontWeight: 'bold' }}>{this.state.productInformation.totalProducts}</span> {this.state.productInformation.totalProducts > 1 ? 'products are' : 'product is'} available to the Wishlist Marketplace</p>
                                                                </div>
                                                                <div style={{ paddingTop: '2rem' }}>
                                                                    <Badge
                                                                        status="success"
                                                                        progress="complete"
                                                                        statusAndProgressLabelOverride="Status: Published">
                                                                        Published
                                                                    </Badge>
                                                                    <span onClick={() => this.showProductWithStatusFilter('published')} className="linkable"> {this.state.productInformation.publishedProducts} products</span>
                                                                </div>
                                                                <div style={{ paddingTop: '2rem' }}>
                                                                    <Badge status="critical" progress="incomplete">Not Published</Badge>
                                                                    <span onClick={() => this.showProductWithStatusFilter('unpublished')} className="linkable"> {this.state.productInformation.unPublishedProducts} products</span>
                                                                </div>
                                                            </div>
                                                                : <div>
                                                                    {this.state.showProductPublishedBanner ? <Banner title="" status="success" onDismiss={() => this.hidePublishingProductBanner()}>
                                                                        <p> <span>{this.state.productInformation.publishedProducts} {this.state.productInformation.publishedProducts > 1 ? 'products' : 'product'} </span><span> published to the Wishlist Marketplace.</span></p>
                                                                    </Banner> : ''}
                                                                    <div style={{ paddingTop: '2rem' }}>
                                                                        <p> <span style={{ fontWeight: 'bold' }}>{this.state.productInformation.totalProducts}</span> products are available to Wishlist Marketplace</p>
                                                                    </div>
                                                                    <div style={{ paddingTop: '2rem' }}>
                                                                        <Badge status="success" progress="complete" statusAndProgressLabelOverride="Status: Published">
                                                                            Published
                                                                        </Badge>
                                                                        <span onClick={() => this.showProductWithStatusFilter('published')} className="linkable"> {this.state.productInformation.publishedProducts} {this.state.productInformation.publishedProducts > 1 ? 'products' : 'product'}</span>
                                                                    </div>
                                                                    <div style={{ paddingTop: '2rem' }}>
                                                                        <Badge status="info" progress="partiallyComplete">Publishing</Badge>
                                                                        <span onClick={() => this.showProductWithStatusFilter('pending')} className="linkable"> {this.state.productInformation.publishing} {this.state.productInformation.publishing > 1 ? 'products' : 'product'}</span>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    }
                                                </>}
                                        </Card.Section>
                                        <Card.Section>
                                            <TextContainer>
                                                Wishlist Marketplace can take up to 7 business days to review the published products and make them available for selection.
                                            </TextContainer>
                                        </Card.Section>
                                    </Card>
                                </div>
                            </Layout.AnnotatedSection>
                                <Layout.AnnotatedSection
                                    id="refresh"
                                    title="Sync Products"
                                    description="Sync your products with Wishlist Marketplace.">
                                    <div style={{ paddingTop: '20px' }}>
                                        <Card sectioned>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>{this.state.productSyncMessage}</div>
                                                <Button loading={this.state.productSyncInProcess} onClick={this.syncSellerProducts} primary style={{ minWidth: '130px' }}>Sync products</Button>
                                            </div>
                                        </Card>
                                    </div>
                                </Layout.AnnotatedSection>
                            </> : ''
                            }
                        </Layout>
                    </Page>
                </Frame>
                <div className="disconnect-modal">
                    <Modal
                        open={this.state.modalActive}
                        onClose={this.toggleDisconnectModal}
                        title="Disconnect Wishlist Marketplace Account?"
                        primaryAction={{
                            content: 'Disconnect',
                            onAction: this.disconnectAccount,
                            className: 'Disconnect',
                            loading: this.state.disconnectingAccount
                        }}
                        secondaryActions={{
                            content: 'Cancel',
                            onAction: this.toggleDisconnectModal
                        }}>
                        <Modal.Section>
                            <Stack vertical>
                                <Stack.Item>
                                    <TextContainer>
                                        <p>
                                            Disconnecting your Wishlist Marketplace account will remove your products from Wishlist Marketplace.
                                            You can reconnect your account at anytime to continue selling on Wishlist Marketplace.
                                        </p>
                                    </TextContainer>
                                </Stack.Item>
                            </Stack>
                        </Modal.Section>
                    </Modal>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { status: state.state, appConfig: state.appConfig, userConfig: state.userConfig }
}

export default connect(mapStateToProps)(AccountConnected);

function Requirement() {
    return (
        <span>Complete this
            <Link url={process.env.REACT_APP_CHECKLIST} external>
                <span className="company-terms" style={{ margin: '0 4px' }}>checklist</span>
            </Link>
            before you connect your accounts to ensure your shop meets
            <span style={{ marginLeft: '2px' }}> <Link url={process.env.REACT_APP_TnC_URL} external>
                Wishlist Marketplace guidelines
            </Link> </span>
        </span>
    )
}

function getEmail(str) {
    if (str.includes(':'))
        return str.split(':')[1];
    return str;
}