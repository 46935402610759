import * as Forge from 'node-forge';
import { rsaEncryptKeyLocal, rsaEncryptKeyStaging, rsaEncryptKeyProd } from '../constants/encryption.key';
import { RsaEncryptType } from '../decorators/rsa.decorators'
import { Environment } from '../model/environment';

function getEncryptedValue(valueToEncrypt) {
    let env = process.env.REACT_APP_ENV;
    let rsaKey = "";
    switch (env) {
        case Environment.staging:
            rsaKey = rsaEncryptKeyStaging;
            break;
        case Environment.production:
            rsaKey = rsaEncryptKeyProd;
            break;
        default:
            rsaKey = rsaEncryptKeyLocal;
            break;
    }
    return encrypted(rsaKey, valueToEncrypt);
}

function encrypted(key, valueToEncrypt) {
    if (!key || !valueToEncrypt?.trim()) {
        return undefined;
    }
    const rsa = Forge.pki.publicKeyFromPem(key);
    return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
};

export function getEncryptedRequest(body) {
    if (body?._rsaEncryptPolicy) {
        let policy = body._rsaEncryptPolicy;
        if (policy.encrypt) {
            let newBody = {};
            if (policy.type === RsaEncryptType.Class) {
                Object.keys(body).forEach(key => { newBody[key] = getEncryptedValue(body[key]) });
            }
            else {
                newBody = body;
                policy.params.forEach((key) => {
                    newBody[key] = getEncryptedValue(body[key]);
                })
            }
            return newBody;
        }
    }

    return body;
}