export const Host = "host";
export const AccessToken = "access_token";
export const Config = "config";
export const User = "user";
export const Orders = "orders";
export const Products = "products";
export const AccountMenu = "account"; 
export const ShopifyHostNameRegex = /[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com[\/]?/;
export const Pending = 'Pending';
export const Rejected = 'Rejected';
export const Accepted = 'Accepted';
export const Disconnected = 'Disconnected';
export const ShowBanner = 'ShowBanner';

