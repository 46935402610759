import { RsaEncryptedParam } from "../../decorators/rsa.decorators";

export class SignUp {
    fullName: string;
    @RsaEncryptedParam() email: string;
    company: string;
    location: string;
    industry: string;
    @RsaEncryptedParam() contactNumber: string;

    constructor(fullName: string, email: string, company: string, location: string, industry: string, contactNumber: string) {
        this.fullName = fullName;
        this.email = email;
        this.company = company;
        this.location = location;
        this.industry = industry;
        this.contactNumber = contactNumber;
    }
}