import React from 'react';
import { connect } from "react-redux";
import { Spinner } from '@shopify/polaris';
import WithRouter from "./app-route/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider, TitleBar } from "@shopify/app-bridge-react";

import instance from './api';
import { AccountMenu } from './model/key';
import appService from './services/app.service';
import { setUserConfig } from './store/actions';

class AppExtension extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: props.config,
            isPageLoading: true
        }
        this.getConfig = this.getConfig.bind(this);
        this.postConfigProcess = this.postConfigProcess.bind(this);
    }

    componentDidMount() {
        this.getConfig();
    }

    getConfig() {
        instance.get('/api/shopify/config').then(response => {
            if (response.success) {
                this.props.setUserConfig(response.data)
                this.postConfigProcess();
            } else {
                this.props.setUserConfig({ sellerStatus: 'Disconnected', isShopValid: true, Message: '' })
                this.postConfigProcess();
            }
        }).catch(error => {
            this.props.setUserConfig({ sellerStatus: 'Disconnected', isShopValid: true, Message: '' })
            this.postConfigProcess();
        });
    }

    postConfigProcess() {
        const { userConfig } = this.props;
        if (!userConfig)
            this.props.setUserConfig({ sellerStatus: 'Disconnected', isShopValid: true, Message: '' })

        if (userConfig.sellerStatus === 'Accepted') {
            appService.setMenu(AccountMenu, true, this.props.appConfig.host);
        } else {
            appService.setMenu(AccountMenu, false, this.props.appConfig.host);
        }
        this.setState({ isPageLoading: false });
    }

    render() {
        return (
            <div>
                {this.state.isPageLoading ? <div>
                    <div className="spinner">
                        <Spinner accessibilityLabel="" size="large" />
                    </div>
                </div> :
                    <Router>
                        <Provider config={this.state.config}>
                            <TitleBar title="" />
                            {this.loadingComponent}
                            <WithRouter userConfig={this.props.userConfig} />
                        </Provider>
                    </Router>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { userConfig: state.userConfig, appConfig: state.appConfig };
};

export default connect(mapStateToProps, { setUserConfig })(AppExtension);
