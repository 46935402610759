import createApp from '@shopify/app-bridge';
import { ChannelMenu, AppLink } from '@shopify/app-bridge/actions';
import { Orders, AccountMenu, Products } from '../model/key';

const appService = {
    getApp: function (host) {
        if (window.self !== window.top) {
            const app = createApp({
                apiKey: process.env.REACT_APP_API_KEY,
                host: host
            });
            return app;
        }
    },
    setMenu: function (menu, loggedIn = true, host) {
        if (window.self !== window.top) {
            const app = this.getApp(host);

            const productsLink = AppLink.create(app, {
                label: 'Products',
                destination: '/products',
            });
            const ordersLink = AppLink.create(app, {
                label: 'Orders',
                destination: '/orders',
            });
            const accountLink = AppLink.create(app, {
                label: 'Account',
                destination: '/account',
            });

            let channelMenu = null;
            if (loggedIn) {
                channelMenu = ChannelMenu.create(app, {
                    items: [accountLink, productsLink, ordersLink]
                });
                if (menu === AccountMenu) {
                    channelMenu.set({ active: accountLink });
                }
                if (menu === Orders) {
                    channelMenu.set({ active: ordersLink });
                }
                if (menu === Products) {
                    channelMenu.set({ active: productsLink });
                }
            } else {
                channelMenu = ChannelMenu.create(app, {
                    items: [accountLink]
                });
                channelMenu.set({ active: accountLink });
            }
        }
    }
}

export default appService;