import {
  combineReducers
} from "redux";

const configReducer = (config = null, action) => {
  if (action.type === "SET_APP_CONFIG") {
    return action.appConfig;
  }
  return config;
};

const userConfigReducer = (userConfig = null, action) => {
  if (action.type === "SET_USER_CONFIG") {
    return action.userConfig;
  }
  return userConfig;
};

const userReducer = (user = "", action) => {
  if (action.type === "USER") {
    return action.user;
  }
  return user;
};

export default combineReducers({
  appConfig: configReducer,
  userConfig: userConfigReducer,
  user: userReducer,
});