const setAppConfig = (appConfig) => {
    return {
        type: 'SET_APP_CONFIG',
        appConfig: appConfig
    }
}

export const setUserConfig = (userConfig) => {
    return {
        type: 'SET_USER_CONFIG',
        userConfig
    }
}

export const setUser = (user) => {
    return {
        type: 'USER',
        user
    }
}

export default setAppConfig;