import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ConnectionProcessing from './Connection-process';
import { ShopifyHostNameRegex } from '../model/key';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function Connecting(props) {
    let query = props.query.shopifyData;
    let path = props.query.path;
    let searchParams = props.query.searchParams;
    const FAILED_STATUS = 'failed';
    const SUCCEED_STATUS = 'success';
    const [connectionStatus, setConnectionStatus] = useState('connecting');
    let { code, hmac, shopOrigin, state, timestamp } = query;

    useEffect(() => {
        if (path === '/auth') {
            let stateNonce = localStorage.getItem('nonce');
            if (!stateNonce || !state || state !== stateNonce || !ShopifyHostNameRegex.test(shopOrigin)) {
                setConnectionStatus(FAILED_STATUS);
            }
            else if (!code) {
                setConnectionStatus(FAILED_STATUS);
            } else {
                let lexicographicallyQueryString = getLexicalQueryStringByRemovingHmac(searchParams);
                let headers = { 'Content-Type': 'text/html', 'X-Signature': hmac, 'X-Timestamp': timestamp };
                axios.post(BASE_URL + `api/v2/catalog/shopify/${shopOrigin}/code/${code}/merchant/connect`, lexicographicallyQueryString, { headers: headers }).then(response => {
                    setConnectionStatus(SUCCEED_STATUS)
                    if (response?.data?.data) {
                        window.setTimeout(() => {
                            window.location.href = (window.location.href + '&connectionComplete=true');
                        }, 3000)
                    } else {
                        setConnectionStatus(FAILED_STATUS);
                    }
                }, error => {
                    setConnectionStatus(FAILED_STATUS);
                });
            }
        } else {
            axios.get(BASE_URL + 'api/v2/catalog/shopify/credentials/get').then(result => {
                let cred = JSON.parse(window.atob(result.data.data));
                let lexicographicallyQueryString = getLexicalQueryStringByRemovingHmac(searchParams);
                axios.post(BASE_URL + 'api/v2/catalog/shopify/request/authenticity/check', lexicographicallyQueryString, { headers: { 'Content-Type': 'text/html', 'X-Signature': hmac, 'X-Timestamp': timestamp } }).then(response => {
                    if (response?.data?.data) {
                        let nonce = Math.random().toString();
                        localStorage.setItem('nonce', nonce)
                        let permissionUrl = `https://${shopOrigin}/admin/oauth/authorize?client_id=${cred.ApiKey}&scope=${cred.Scopes}&redirect_uri=${cred.RedirectUri}&state=${nonce}`;
                        window.location.href = permissionUrl;
                    } else {
                        setConnectionStatus(FAILED_STATUS);
                    }
                }, error => {
                    setConnectionStatus(FAILED_STATUS);
                });
            }, error => {
                setConnectionStatus(FAILED_STATUS);
            });
        }
    }, [path, code, hmac, shopOrigin, state, timestamp, searchParams])

    return (
        <span>
            <ConnectionProcessing status={{ connectionStatus }} />
        </span>
    );
}

function getLexicalQueryStringByRemovingHmac(queryString = "") {
    let queryParamSplits = queryString.split('&');
    let index = queryParamSplits.findIndex(row => row?.toLowerCase()?.includes('hmac='));
    if (index >= 0) {
        queryParamSplits.splice(index, 1);
        let modifiedArray = queryParamSplits.map(x => {
            if (x.startsWith('?')) {
                return x.substring(1);
            } else {
                return x;
            }
        });
        modifiedArray.sort();
        return modifiedArray.join('&');
    } else {
        queryString = queryString.replace('?', '');
    }
    return queryString;
}

export default Connecting;