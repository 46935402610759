
import React, { useEffect, useState, useCallback } from "react";
import { useIndexResourceState, Card, Filters, IndexTable, Page, Pagination } from '@shopify/polaris';
import { TitleBar } from "@shopify/app-bridge-react";
import { Redirect } from '@shopify/app-bridge/actions';

import instance from "../api";
import Footer from '../Components/footer/footer';
import appService from '../services/app.service';
import { Orders } from "../model/key";
import { connect } from "react-redux";

function Order(props) {
    const appBridge = appService.getApp(props.appConfig.host);
    const redirect = Redirect.create(appBridge);
    const editableColumn = { textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' };
    const resourceName = {
        singular: 'Order',
        plural: 'Orders',
    };
    const [queryValue, setQueryValue] = useState(null);
    const [orderList, setOrderList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [nextCursor, setNextCursor] = useState('');
    const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(orderList);
    
    const handleQueryKeyChange = useCallback((value) => {
        setQueryValue(value);
    }, []);
   
    const handleQueryValueRemove = useCallback(() => {
        setQueryValue(null)
        fetchOrderData('');
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleClearAll = useCallback(() => {
        handleQueryValueRemove();
    }, [handleQueryValueRemove]);

    const fetchOrderData = async (query, cb) => {
        setIsLoading(true);
        query = query ? query : '';
        instance.get(`api/shopify/orders/get?nextCursor=${nextCursor}&filter=${query}`)
            .then(res => res.data)
            .then(response => {
                let orders = response?.data?.orders?.length > 0 ? response?.data.orders : [];
                setOrderList(orders);
                setNextCursor(response?.data?.nextCursor ? response?.data?.nextCursor : '');
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false)
            });
    };

    const filters = [];

    const appliedFilters = !isEmpty('')
        ? []
        : [];

    const rowMarkup = orderList.map(
        ({ id, order_number, total_price, financial_status, currency, line_items, created_at }, index) => (

            <IndexTable.Row
                id={order_number}
                key={order_number}
                selected={selectedResources.includes(order_number)}
                position={index}>
                <IndexTable.Cell> <span onClick={() => openOrderPage(id)} style={editableColumn}>#{order_number} </span></IndexTable.Cell>
                <IndexTable.Cell>{formatDate(created_at)}</IndexTable.Cell>
                <IndexTable.Cell>{linkProduct(line_items)}</IndexTable.Cell>
                <IndexTable.Cell>{currency}</IndexTable.Cell>
                <IndexTable.Cell>{total_price}</IndexTable.Cell>
                <IndexTable.Cell> <span style={{ textTransform: 'capitalize' }}>{financial_status}</span></IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    function linkToProductItem(id) {
        redirect.dispatch(Redirect.Action.ADMIN_PATH, {
            path: '/products/' + id,
            newContext: false,
        });
    }

    function openOrderPage(id) {
        redirect.dispatch(Redirect.Action.ADMIN_PATH, {
            path: '/orders/' + id,
            newContext: false,
        });
    }

    useEffect(() => {
        appService.setMenu(Orders, true, props.appConfig.host);
        fetchOrderData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page fullWidth title="Orders">
            <TitleBar title="Orders" />
            <Card>
                <div style={{ padding: '16px', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <Filters
                            queryValue={queryValue}
                            filters={filters}
                            appliedFilters={appliedFilters}
                            onQueryChange={handleQueryKeyChange}
                            onQueryClear={handleQueryValueRemove}
                            onClearAll={handleClearAll}
                        />
                    </div>
                </div>
                <IndexTable
                    resourceName={resourceName}
                    itemCount={orderList.length}
                    selectedItemsCount={
                        allResourcesSelected ? 'All' : selectedResources.length
                    }
                    onSelectionChange={handleSelectionChange}
                    loading={isLoading}
                    headings={[
                        { title: 'Order No.' },
                        { title: 'Order Date' },
                        { title: 'Product Name' },
                        { title: 'Currency' },
                        { title: 'Total' },
                        { title: 'Status' },
                    ]}
                    selectable={false}>
                    {rowMarkup}
                </IndexTable>
            </Card>

            <div style={{ display: 'grid', placeContent: 'center', marginTop: '10px' }}>
                {
                    (orderList?.length > 0 && nextCursor) ?
                        <Pagination
                            hasNext
                            onNext={() => {
                                setIsLoading(true);
                                instance.get(`api/shopify/orders/get?nextCursor=${nextCursor}&filter=${queryValue}`)
                                    .then(res => res.data.data)
                                    .then(response => {
                                        let orders = response?.data?.orders?.length > 0 ? response?.data.orders : [];
                                        setOrderList([...orderList, ...orders]);
                                        setNextCursor(response?.data?.nextCursor ? response?.data?.nextCursor : '');
                                        setIsLoading(false);
                                    }, error => {
                                        setIsLoading(false);
                                    });
                            }}
                        />
                        : ""
                }
            </div>
            <Footer />
        </Page>
    );

    function isEmpty(value) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value == null;
        }
    }

    function linkProduct(line_items) {
        if (line_items?.length > 0) {
            return <span style={{ cursor: "pointer", textDecoration: "underline", maxWidth: "330px" }} onClick={() => linkToProductItem(line_items[0]?.product_id)}>{line_items[0]?.name}</span>
        }
    }

    function formatDate(date) {
        if (!date) return "";
        return new Date(date).toLocaleString();
    }
}

const mapStateToProps = state => {
    return { appConfig: state.appConfig };
};

export default connect(mapStateToProps)(Order);