import { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '@shopify/app-bridge-react';
import { TitleBar } from "@shopify/app-bridge-react";
import { DisplayText, Button, Modal, TextField, Form, FormLayout, Toast, Frame, Banner } from '@shopify/polaris';

import instance from '../../api';
import Footer from '../footer/footer';
import { AccountMenu } from '../../model/key';
import AccountConnected from './account.connected';
import appService from '../../services/app.service';
import { Disconnected, Rejected, Accepted, Pending } from '../../model/key';
import { setUser, setUserConfig } from '../../store/actions';
import { getSessionToken } from "@shopify/app-bridge-utils";

import './account.css';
import { LoginDto } from '../../model/account/login';
import { SignUp } from '../../model/account/signup';
export const EmailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]+$/

class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            activeSignUpModal: false,
            userId: '',
            password: '',
            userIdError: '',
            passwordError: '',
            fullName: '',
            contact: '',
            location: '',
            email: '',
            industry: '',
            processing: false,
            toasterMessage: null,
            fullNameError: '',
            contactError: '',
            emailError: '',
            signUpSuccessMessage: null
        }

        this.toggleLoginModal = this.toggleLoginModal.bind(this);
        this.handleUserIdChange = this.handleUserIdChange.bind(this);
        this.handleLoginFormSubmit = this.handleLoginFormSubmit.bind(this);
        this.handleUserPasswordChange = this.handleUserPasswordChange.bind(this);
        this.toggleSignUpModal = this.toggleSignUpModal.bind(this);
        this.handleSignUpFormSubmit = this.handleSignUpFormSubmit.bind(this);
        this.postLoginTask = this.postLoginTask.bind(this);
        this.showMessageToaster = this.showMessageToaster.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.accountDisconnected = this.accountDisconnected.bind(this);
        this.createAppNavigationMenus = this.createAppNavigationMenus.bind(this);
        this.accountStatusUpdate = this.accountStatusUpdate.bind(this);
        this.validateLoginEmail = this.validateLoginEmail.bind(this);
        this.validateSignUpEmail = this.validateSignUpEmail.bind(this);
        this.handleFullNameChange = this.handleFullNameChange.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.isEmailValid = this.isEmailValid.bind(this);
    }

    handleFullNameChange = (value) => {
        this.setState({ fullName: value });
        this.setState({ fullNameError: null });
    }

    handleContactChange = (value) => {
        this.setState({ contact: value });
        this.setState({ contactError: null });
    }

    handleEmailChange = (value) => {
        this.setState({ email: value });
        this.setState({ emailError: null });
    }

    validateLoginEmail = (email) => {
        this.isEmailValid(email) ? this.setState({ userIdError: null }) : this.setState({ userIdError: 'Email address is not valid' });
    }

    validateSignUpEmail = (email) => {
        if (!this.isEmailValid(email)) {
            this.setState({ emailError: "email" });
        } else {
            this.setState({ emailError: null });
        }
    }

    isEmailValid(email) {
        if (email)
            return (!EmailRegex.test(email)) ? false : true;
        return true;
    }

    toggleLoginModal(e) {
        let active = !this.state.active;
        this.setState({ active: active });
        this.setState({ userId: '' });
        this.setState({ password: '' });
        this.setState({ processing: false });
        this.setState({ userIdError: null });
        this.setState({ passwordError: null });
    }
    toggleSignUpModal(e) {
        this.setState({ activeSignUpModal: !this.state.activeSignUpModal });
        this.setState({ fullName: '' });
        this.setState({ contact: '' });
        this.setState({ location: '' });
        this.setState({ industry: '' });
        this.setState({ email: '' });
        this.setState({ company: '' });
        this.setState({ processing: false });
        this.setState({ emailError: null });
        this.setState({ contactError: null });
        this.setState({ fullNameError: null });
    }

    postLoginTask(loginFailedReason) {
        setTimeout(() => {
            this.showMessageToaster(loginFailedReason);
            this.setState({ processing: false });
        }, 1000);
    }

    showMessageToaster(message) {
        this.setState({ toasterMessage: message });
    }

    handleLoginFormSubmit(e) {
        if (!this.state.userId?.trim()) {
            this.setState({ userIdError: "Username is required" });
            return;
        } else {
            this.setState({ userIdError: "" });
        }
        if (!this.isEmailValid(this.state.userId)) {
            this.setState({ userIdError: "Email address is not valid" });
            return;
        } else {
            this.setState({ userIdError: "" });
        }
        if (!this.state.password?.trim()) {
            this.setState({ passwordError: "Password is required" });
            return;
        }
        else {
            this.setState({ passwordError: "" });
            try {
                this.setState({ processing: true })
                getSessionToken(appService.getApp(this.props.appConfig.host)).then(token => {
                    const { userId, password } = this.state;
                    const loginFormData = new LoginDto(userId, password, `Bearer ${token}`);
                    
                    instance.post('api/v2/oauth/login', loginFormData, { "Content-Type": "application/json" })
                        .then(response => {
                            if (response?.access_token) {
                                this.props.setUser(response)
                                this.accountStatusUpdate();
                            } else {
                                this.postLoginTask(response?.errorDescription ? response?.errorDescription : '');
                            }
                        }).catch(error => {
                            this.postLoginTask(error?.response?.error);
                        });
                });
            } catch (error) {
                this.postLoginTask('Login failed !');
            }
        }
    }

    accountStatusUpdate() {
        instance.post('/api/shopify/vendor/status/update')
            .then(response => {
                if (response.success) {
                    this.getConfig();
                } else {
                    this.postLoginTask('Login failed!');
                }
            }).catch(error => {
                this.postLoginTask(error.message);
            })
    }

    getConfig() {
        instance.get('/api/shopify/config').then(response => {
            if (response.success) {
                this.props.setUserConfig(response.data);
                this.setState({ config: response.data });
                this.createAppNavigationMenus(response?.data?.sellerStatus);
                this.setState({ active: false });
            } else {
                this.postLoginTask('Could not fetch configuration information. Please contact support: ' + process.env.REACT_APP_SUPPORT);
            }
        }).catch(error => {
            this.postLoginTask(error.message);
        });
    }

    createAppNavigationMenus(sellerStatus) {
        if (sellerStatus === Accepted) {
            appService.setMenu(AccountMenu, true, this.props.appConfig.host);
        } else {
            appService.setMenu(AccountMenu, false, this.props.appConfig.host);
        }
    }

    handleSignUpFormSubmit(e) {
        let { fullName, email, company, location, industry, contact } = this.state;
        let payload = new SignUp(fullName, email, company, location, industry, contact);
        
        if (!payload.fullName?.trim()) {
            this.setState({ fullNameError: 'required' });
        }
        if (!payload.contactNumber?.trim()) {
            this.setState({ contactError: 'required' });
        }
        if (!payload.email?.trim()) {
            this.setState({ emailError: 'required' });
        }
        if (!payload.fullName || !payload.contactNumber || !payload.email || !this.isEmailValid(payload.email)) return;    
        this.setState({ processing: true });       
        instance.post('/api/shopify/sign-up/info', payload).then(response => {
            if (response.success) {
                setTimeout(() => {
                    this.toggleSignUpModal();
                    this.setState({ signUpSuccessMessage: response.data });
                }, 1000);
            } else {
                this.showMessageToaster(response.message);
                this.setState({ processing: false });
            }
        }).catch(error => {
            this.setState({ processing: false });
            this.showMessageToaster(error.message);
        });
    }

    handleUserIdChange(e) {
        this.setState({ userId: e });
        this.setState({ userIdError: '' });
    }

    handleUserPasswordChange(e) {
        this.setState({ password: e });
        this.setState({ passwordError: '' });
    }

    accountDisconnected(data) {
        this.props.setUserConfig(data);
        this.props.setUser('');
        this.setState({ config: data });
        this.createAppNavigationMenus(false);
    }

    render() {
        return (
            (window.self === window.top) ?
                <div>
                    <Loading />
                </div>
                :
                <Frame>
                    <div style={{ paddingBottom: '50px' }}>

                        {this.state.toasterMessage ? <Toast content={this.state.toasterMessage} onDismiss={() => this.setState({ toasterMessage: null })} /> : null}

                        <TitleBar title="Account" />
                        {
                            this.props.userConfig.sellerStatus === Disconnected ?
                                <div>
                                    {this.state.signUpSuccessMessage != null ? <div className="sign-up-banner-container">
                                        <div className="sign-up-banner">
                                            <Banner
                                                status="info"
                                                onDismiss={() => this.setState({ signUpSuccessMessage: null })}
                                            >
                                                <p>{this.state.signUpSuccessMessage}</p>
                                            </Banner>
                                        </div>
                                    </div> : <></>}
                                    <div className="account-page">
                                        <div className="account-information">
                                            <div style={{ maxWidth: '390px', fontWeight: '500' }}>
                                                <DisplayText size="medium" className="title">Sell your products on Wishlist Marketplace</DisplayText>
                                            </div >
                                            <div style={{ maxWidth: '460px', color: '#090909a3' }}>
                                                <DisplayText size="small">Reach thousands of potential customers while they are browsing for their perfect gifts in the Wishlist Marketplace app</DisplayText>
                                            </div>
                                            <div>
                                                <div className="connect-account">
                                                    <Button onClick={this.toggleLoginModal}>Connect account</Button>
                                                </div>
                                                <br />
                                                <span className="sign-up" onClick={this.toggleSignUpModal}> Sign up for Wishlist Marketplace </span>
                                            </div>
                                        </div>
                                        <div className="left-side">
                                        </div>
                                        <div className="image">
                                            <img style={{ width: '100%', height: '110vh' }} src="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg" alt="empty-state" />
                                        </div>
                                    </div>
                                </div>
                                :
                                (

                                    (this.props.userConfig.sellerStatus === Accepted || this.props.userConfig.sellerStatus === Rejected || this.props.userConfig.sellerStatus === Pending) ?
                                        <div>
                                            <AccountConnected accountDisconnected={this.accountDisconnected} config={this.props.userConfig} />
                                        </div>
                                        : <div></div>
                                )
                        }
                        {this.toastMarkup}
                        <div>
                            <Footer />
                        </div>

                        {/* Login Modal */}
                        <div>
                            <Modal small
                                open={this.state.active}
                                onClose={this.toggleLoginModal}
                            >
                                <div className="company-logo-wrapper">
                                    <img className="account-logo" src="../../../Wishlist_Logo_Color.png" alt="" />
                                    <div className="sign-in-logo">Wishlist Marketplace</div>
                                </div>
                                <div style={{ padding: '0 40px 20px 40px' }}>
                                    <Modal.Section>
                                        <Form noValidate onSubmit={this.handleLoginFormSubmit}>
                                            <FormLayout>
                                                <TextField
                                                    value={this.state.userId}
                                                    onChange={this.handleUserIdChange}
                                                    label="Email or username"
                                                    type="email"
                                                    autoComplete="email"
                                                    helpText={''}
                                                    placeholder="someone@company.com"
                                                    error={this.state.userIdError ? <ErrorMessage message={this.state.userIdError} /> : null}
                                                    autoFocus
                                                    onBlur={e => this.validateLoginEmail(this.state.userId)}
                                                />
                                                <TextField
                                                    value={this.state.password}
                                                    onChange={this.handleUserPasswordChange}
                                                    label="Password"
                                                    type="password"
                                                    autoComplete="off"
                                                    helpText={''}
                                                    placeholder="**********************"
                                                    required
                                                    error={this.state.passwordError ? <ErrorMessage message={this.state.passwordError} /> : null}
                                                />
                                                <Button disabled={this.state.processing} loading={this.state.processing ? true : false} submit fullWidth> {this.state.processing ? '' : 'Connect'}</Button>
                                            </FormLayout>
                                        </Form>
                                    </Modal.Section>
                                </div>
                                <div style={{ textAlign: 'center', paddingBottom: '30px', opacity: '.8' }}>
                                    <div>By connecting your account</div>
                                    <div>you agree to the Wishlist Marketplace</div>
                                    <AccountTnC />
                                </div>
                            </Modal>
                        </div>
                        {/* Sign up Modal */}
                        <div style={{ padding: '20px' }}>
                            <Modal role="dialog"
                                open={this.state.activeSignUpModal}
                                onClose={this.toggleSignUpModal}>
                                <div className="company-logo-wrapper" style={{ marginLeft: '0', justifyContent: 'center' }}>
                                    <img className="account-logo" src="../../../Wishlist_Logo_Color.png" alt="" />
                                    <div className="sign-in-logo">Wishlist Marketplace</div>
                                </div>
                                <div style={{ padding: '10px 39px' }}>
                                    <Modal.Section>
                                        <Form noValidate onSubmit={this.handleSignUpFormSubmit}>
                                            <FormLayout>
                                                <FormLayout.Group>
                                                    <TextField
                                                        value={this.state.fullName}
                                                        onChange={e => this.handleFullNameChange(e)}
                                                        label={<span>Full Name<span className="required">*</span></span>}
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Enter Full Name"
                                                        name="fullName"
                                                        clearButton
                                                        onClearButtonClick={() => this.handleFullNameChange('')}
                                                        error={this.state.fullNameError === 'required' ? <ErrorMessage message="Full name is required" /> : null}
                                                        autoFocus
                                                    />
                                                    <TextField
                                                        value={this.state.company}
                                                        onChange={e => this.setState({ company: e })}
                                                        label="Company"
                                                        type="text"
                                                        autoComplete="off"
                                                        helpText={''}
                                                        placeholder="Enter Company Name"
                                                        error={''}
                                                        name="company"
                                                        clearButton
                                                        onClearButtonClick={() => this.setState({ company: '' })}
                                                    />
                                                </FormLayout.Group>
                                                <FormLayout.Group>
                                                    <TextField
                                                        value={this.state.contact}
                                                        onChange={e => this.handleContactChange(e)}
                                                        label={<span>Contact No.<span className="required">*</span></span>}
                                                        type="tel"
                                                        autoComplete="off"
                                                        helpText={''}
                                                        placeholder="999-999-9999"
                                                        name="contact"
                                                        clearButton
                                                        onClearButtonClick={() => this.handleContactChange('')}
                                                        error={this.state.contactError === 'required' ? <ErrorMessage message="Contact number is required" /> : null}
                                                    />
                                                    <TextField
                                                        value={this.state.email}
                                                        onChange={e => this.handleEmailChange(e)}
                                                        label={<span>Email<span className="required">*</span></span>}
                                                        type="email"
                                                        autoComplete="email"
                                                        helpText={''}
                                                        placeholder="someone@company.com"
                                                        clearButton
                                                        onClearButtonClick={() => this.handleEmailChange('')}
                                                        error={this.state.emailError === 'required' ? <ErrorMessage message="Email is required" /> : this.state.emailError === 'email' ? <ErrorMessage message="Email address is not valid" /> : null}
                                                        onBlur={e => this.validateSignUpEmail(this.state.email)}
                                                    />
                                                </FormLayout.Group>
                                                <FormLayout.Group>
                                                    <TextField
                                                        value={this.state.location}
                                                        onChange={e => this.setState({ location: e })}
                                                        label="Location"
                                                        type="text"
                                                        autoComplete="off"
                                                        helpText={''}
                                                        placeholder="Location"
                                                        error={''}
                                                        name="location"
                                                        clearButton
                                                        onClearButtonClick={() => this.setState({ location: '' })}
                                                    />
                                                    <TextField
                                                        value={this.state.industry}
                                                        onChange={e => this.setState({ industry: e })}
                                                        label="Industry"
                                                        type="text"
                                                        autoComplete="off"
                                                        helpText={''}
                                                        placeholder="Industry"
                                                        error={''}
                                                        name="industry"
                                                        clearButton
                                                        onClearButtonClick={() => this.setState({ industry: '' })}
                                                    />
                                                </FormLayout.Group>
                                                <Button disabled={this.state.processing} loading={this.state.processing} submit fullWidth> {this.state.processing ? '' : 'Sign up'} </Button>
                                            </FormLayout>
                                        </Form>
                                    </Modal.Section>
                                </div>
                                <div style={{ textAlign: 'center', paddingBottom: '30px', opacity: '.8' }}>
                                    <div>By signing up</div>
                                    <div>you agree to the Wishlist Marketplace</div>
                                    <AccountTnC />
                                </div>
                            </Modal>
                        </div>
                    </div>
                </Frame>
        )
    }
}

const mapStateToProps = state => {
    return { user: state.user, userConfig: state.userConfig, appConfig: state.appConfig };
};

export default connect(mapStateToProps, { setUserConfig, setUser })(Account);

const ErrorMessage = (props) => {
    return <span className="error-helper">{props?.message}</span>
}

const AccountTnC = (props) => {
    return <a target="blank" href={process.env.REACT_APP_TnC_URL} className="term_and_conditions">terms & conditions</a>
}