import { useEffect, useState, useCallback } from "react";
import { TitleBar } from "@shopify/app-bridge-react";
import { Redirect } from '@shopify/app-bridge/actions';
import { Products } from "../model/key";
import appService from "../services/app.service";
import debounce from 'lodash.debounce';
import './Product.css';
import instance from "../api";
import Footer from '../Components/footer/footer';

import {
    useIndexResourceState, Card, Filters, IndexTable, TextStyle, Page, Thumbnail,
    Pagination, Frame, Loading, ChoiceList, EmptySearchResult
} from '@shopify/polaris';
import { connect } from "react-redux";

function Product(props) {
    const resourceName = {
        singular: 'product',
        plural: 'products',
    };
    const appBridge = appService.getApp(props.appConfig.host);
    const editableColumn = { textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' };
    const redirect = Redirect.create(appBridge);
    const [productStatus, setProductStatus] = useState([]);
    const [queryValue, setQueryValue] = useState(null);
    const [productList, setProductList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [nextCursor, setNextCursor] = useState('');
    const [noProductMessage, setNoProductMessage] = useState('');
    const [filterType, setFilterType] = useState('');

    const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(productList);
    const handleProductStatusRemove = useCallback(() => {
        setProductStatus([])
        fetchProductData();
    }, []);

    const handleQueryKeyChange = useCallback((value) => {
        setQueryValue(value);
        debouncedFetchData(value, res => { });
    }, []);

    const handleQueryValueRemove = useCallback(() => {
        setQueryValue(null)
        fetchProductData('');
    }, []);
    const handleClearAll = useCallback(() => {
        handleProductStatusRemove();
        handleQueryValueRemove();
    }, [handleQueryValueRemove, handleProductStatusRemove]);

    const debouncedFetchData = debounce((query) => {
        fetchProductData(query);
    }, 500);

    const fetchProductData = async (query, isPublished) => {
        setIsLoading(true);
        query = query ? query : '';
        let publishedProducts = isPublished === false ? false : true;

        const productStatusFilterValue = filterType;
        if (productStatusFilterValue) {
            setProductStatus([productStatusFilterValue]);
            if (productStatusFilterValue === 'published') {
                publishedProducts = true;
            } else {
                publishedProducts = false
            }
        }

        instance.get(`api/shopify/products/get?nextCursor=${nextCursor}&filter=${query}&publish=${publishedProducts}`)
            .then(res => {
                let products = res.data?.length > 0 ? res.data : [];
                setProductList(products);
                setIsLoading(false);
                (res?.data?.length === 0) ? setNoProductMessage('Please try other filters or search term') : setNoProductMessage('');
            })
            .catch(error => {
                setIsLoading(false)
                setNoProductMessage(error?.message)
            });
    };

    const handleProductStatusChange = useCallback((value) => {
        setProductStatus(value)
        let status = value.length >= 1 ? value[0] : '';
        let isPublished = status === 'published' ? true : false;
        fetchProductData(queryValue, isPublished);
    }, [queryValue]);

    function openProductEditView(id) {
        redirect.dispatch(Redirect.Action.ADMIN_PATH, {
            path: '/products/' + id,
            newContext: false,
        });
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        let query = Object.fromEntries(urlSearchParams.entries());
        if (query?.type) {
            setFilterType(query.type)
        }
    }, [])

    useEffect(() => {
        appService.setMenu(Products, true, props.appConfig.host);
        fetchProductData();
    }, [filterType, props.appConfig.host]);

    const filters = [
        {
            key: 'status',
            label: 'Status',
            filter: (
                <div>
                    <ChoiceList
                        allowMultiple={false}
                        title=""
                        choices={[
                            {
                                label: 'Published',
                                value: 'published'
                            },
                            {
                                label: 'Unpublished',
                                value: 'unpublished'
                            }

                        ]}
                        selected={productStatus}
                        onChange={handleProductStatusChange}
                    /> </div>
            ),
            shortcut: true,
        },
    ];

    const appliedFilters = !isEmpty(productStatus)
        ? [
            {
                key: 'status',
                label: disambiguateLabel('status', productStatus),
                onRemove: handleProductStatusRemove,
            },
        ]
        : [];

    const rowMarkup = productList.map(({ id, title, productType, imageUrl, status, productId, vendorName, errorMessage }, index) => (
        <IndexTable.Row
            id={productId}
            key={index}
            selected={selectedResources.includes(productId)}
            position={productId}
        >
            <IndexTable.Cell>
                <Thumbnail source={imageUrl ? imageUrl : ''} />
            </IndexTable.Cell>
            <IndexTable.Cell>
                <TextStyle variation="strong">
                    {status === 'deleted' ? <span>{title}</span> : <span onClick={() => openProductEditView(productId)} style={editableColumn}>{title}</span>}
                </TextStyle>
            </IndexTable.Cell>
            <IndexTable.Cell> <span style={{ textTransform: 'capitalize' }}>{status}</span></IndexTable.Cell>
            <IndexTable.Cell>{productType}</IndexTable.Cell>
            <IndexTable.Cell>{vendorName}</IndexTable.Cell>
            {productStatus[0] === 'unpublished' ? <IndexTable.Cell>{errorMessage}</IndexTable.Cell> : null}
        </IndexTable.Row>
    ),
    );

    const headings = productStatus[0] === 'unpublished' ? [
        { title: '' },
        { title: 'Product Name' },
        { title: 'Status' },
        { title: 'Type' },
        { title: 'Vendor' },
        { title: 'Error message' }
    ] :
        [{ title: '' },
        { title: 'Product Name' },
        { title: 'Status' },
        { title: 'Type' },
        { title: 'Vendor' },]

    return (
        (window.self === window.top) ?
            <div style={{ height: '100px' }}>
                <Frame>
                    <Loading />
                </Frame>
            </div>
            :
            <Page fullWidth title="Products">
                <TitleBar title="Products" />
                <Card>
                    <div style={{ padding: '16px', display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <Filters
                                queryValue={queryValue}
                                filters={filters}
                                appliedFilters={appliedFilters}
                                onQueryChange={handleQueryKeyChange}
                                onQueryClear={handleQueryValueRemove}
                                onClearAll={handleClearAll}
                            />
                        </div>
                    </div>
                    <IndexTable
                        resourceName={resourceName}
                        itemCount={productList.length}
                        selectedItemsCount={
                            allResourcesSelected ? 'All' : selectedResources.length
                        }
                        onSelectionChange={handleSelectionChange}
                        loading={isLoading}
                        headings={headings}
                        selectable={false}
                        emptyState={<EmptyStateMarkup message={noProductMessage} />}
                    >
                        {rowMarkup}
                    </IndexTable>
                </Card>

                <div style={{ display: 'grid', placeContent: 'center', marginTop: '10px' }}>
                    {
                        (productList?.length > 0 && nextCursor) ?
                            <Pagination
                                hasNext
                                onNext={() => {
                                    setIsLoading(true);
                                    instance.get(`api/shopify/products/get?nextCursor=${nextCursor}&filter=${queryValue}`)
                                        .then(res => res.data.data)
                                        .then(response => {
                                            let products = response?.products?.length > 0 ? response?.products : [];
                                            setProductList([...productList, ...products]);
                                            setNextCursor(response?.nextCursor ? response?.nextCursor : '');
                                            setIsLoading(false);
                                        }, error => {
                                            setIsLoading(false);
                                        });
                                }}
                            />
                            : ""
                    }
                </div>
                <Footer />
            </Page>
    );

    function isEmpty(value) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value === null;
        }
    }

    function disambiguateLabel(key, value) {
        switch (key) {
            case 'status':
                return `Status: ${value}`;
            default:
                return value;
        }
    }
}

function EmptyStateMarkup(props) {
    return <div>
        <EmptySearchResult
            title={'No products found'}
            description={props.message}
            withIllustration />
    </div>
}

const mapStateToProps = state => {
    return { filterStatus: state.filterStatus, appConfig: state.appConfig };
};

export default connect(mapStateToProps)(Product);
