import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";
import appService from "./services/app.service";
import { getEncryptedRequest } from './services/rsa-encryption.service';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL
});

export const quartzInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_QUARTZ
});

if (window.top !== window.self) {
    try {
        let query = GetHost();
        let host = query.host;
        const appInstance = appService.getApp(host)

        instance.interceptors.request.use(function (config) {
            if (config?.method === "post" && config?.data) {
                config['data'] = getEncryptedRequest(config.data);
                return config;
            }
            return config;
        });

        instance.interceptors.request.use(function (config) {
            return getSessionToken(appInstance)
                .then((token) => {
                    config.headers["X-Shopify-Access-Token"] = `Bearer ${token}`;
                    return config;
                });
        });

        instance.interceptors.response.use(function (response) {
            return response.data;
        }, function (error) {
            return Promise.reject(error.response.data ? error.response.data : { message: error.response.statusText });
        });

        quartzInstance.interceptors.request.use(function (config) {
            return getSessionToken(appInstance)
                .then((token) => {
                    config.headers["X-Shopify-Access-Token"] = `Bearer ${token}`;
                    return config;
                });
        });
        
        quartzInstance.interceptors.response.use(function (response) {
            return response.data;
        }, function (error) {
            return Promise.reject(error.response.data ? error.response.data : { message: error.response.statusText });
        });
    } catch (error) {
    }
}

export default instance; //Axios instance to get session token from shopify


function GetHost() {
    const useQuery = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return Object.fromEntries(urlSearchParams.entries());
    };
    return useQuery();
}