import './Connection-process.css';
import { RefreshMajor } from '@shopify/polaris-icons';
import { Icon, Banner } from '@shopify/polaris';
import Mailto from '../helper/mail/mailto';

function ConnectionProcessing(props) {
    let connectionStatus = props.status.connectionStatus;
    return (
        <div className="processing">
            <div style={{ marginBottom: '20px' }}>
                {connectionStatus === 'failed' ?
                    (
                        <Banner
                            title="Connection failed"
                            status="critical">
                            <p>Oops, unfortunately could not established connection. Please contact <br />
                                support: <Mailto mailto={process.env.REACT_APP_SUPPORT} label={process.env.REACT_APP_SUPPORT} />
                            </p>
                        </Banner>
                    ) : (
                        connectionStatus === 'success' ? (<div>
                            <Banner
                                title="Connection success"
                                status="success"
                            >
                                <p>You are now connected with Wishlist.</p>
                            </Banner>
                        </div>) : ""
                    )}
            </div>

            <div className="card">
                <div className="header">
                    <div className="title">App Connect</div>
                    <div className="sub-title">Connecting Wishlist with Shopify</div>
                    <div className="line"></div>
                </div>
                <div className="card-body">
                    <div className="body-content">
                        <div className="logo wishlist animate"></div>
                        <div className="sync-icon">
                            <Icon source={RefreshMajor} color="base" />
                        </div>
                        <div className="logo shopify animate"></div>
                    </div>
                    <div className="line"></div>
                </div>
            </div>
        </div>
    );
}

export default ConnectionProcessing;