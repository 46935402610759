export enum RsaEncryptType {
    Class = 'Class',
    Param = 'Param'
}

export class RsaEncryptPolicy {
    readonly encrypt: boolean = false;
    readonly type: RsaEncryptType = RsaEncryptType.Class;
    readonly params: string[] = [];

    constructor(config?: { encrypt: boolean, type: RsaEncryptType, params: string[] }) {
        if (config?.encrypt) {
            this.encrypt = true;
            this.type = config?.type ?? RsaEncryptType.Class;
            this.params = [...this.params, ...(config.params ?? [])];
        }
    }
}

/**
 * Class decorator that makes it easy to insert transparent transform
 * encryption onto a data transfer object.
 **/
export const RsaEncryptedClass = (): ClassDecorator => {
    return (constructor) => { constructor.prototype._rsaEncryptPolicy = new RsaEncryptPolicy({ encrypt: true, type: RsaEncryptType.Class, params: [] }); };
}

/**
 * Property decorator that makes it easy to modify values
 * encryption onto a data transfer key.
 **/
export const RsaEncryptedParam = () => {
    return (target: Object, propertyKey: string) => {
        let params: string[] = target?.constructor?.prototype?._rsaEncryptPolicy?.params.length > 0 ? target?.constructor?.prototype?._rsaEncryptPolicy.params : [];
        params = [...params, ...[propertyKey]];
        target.constructor.prototype._rsaEncryptPolicy = new RsaEncryptPolicy({ encrypt: true, type: RsaEncryptType.Param, params: params });
    }
};