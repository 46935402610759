import { FooterHelp, Link } from '@shopify/polaris';

export default function Footer() {
    var styled = { width: 'fit-content', borderRadius: '100px', margin: 'auto', outline: '1px solid #d3d3d3' }
    return (
        <div>
            <div style={styled}>
                <FooterHelp>
                    Learn more about{' '}
                    <Link url={process.env.REACT_APP_DOC} external>
                        selling on Wishlist Marketplace
                    </Link>
                </FooterHelp>
            </div>
        </div>
    )
}