import { Switch, Route, withRouter } from 'react-router';
import { ClientRouter, RoutePropagator } from '@shopify/app-bridge-react';
import Product from '../Components/Product';
import Order from '../Components/Order';
import Account from '../Components/account/account';

function Routes(props) {
  const { history, location } = props;
  const isAccepted = props.userConfig.sellerStatus === 'Accepted' ? true : false;
  let isLoggedIn = isAccepted ? true : false;
  return (
    <>
      {isLoggedIn ? <>
        <ClientRouter history={history} />
        <RoutePropagator location={location} />
        <Switch>
          <Route path="/products">
            <Product />
          </Route>
          <Route path="/orders">
            <Order />
          </Route>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/">
            <Account />
          </Route>
        </Switch>
      </> : <>
        <ClientRouter history={history} />
        <RoutePropagator location={location} />
        <Switch>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/">
            <Account />
          </Route>
        </Switch>
      </>}

    </>
  );
};

export default withRouter(Routes);