export const rsaEncryptKeyLocal = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC9PDXAzePK3HpWwKY5AyB/RoIr
g1+Pj6s7VvjXSQVAnNjMJ5QI5JApiWy+Nd9NjEo65AZtS8524coh9/QMEjFEdQ8O
6IRNhetwHfC2fkUBLp0BrQghmGowhln5W/4gUL3eEny9QBF388Y77FwB3QMW/b9V
JnARI6lpxuN6Q5DD+QIDAQAB
-----END PUBLIC KEY-----`;

export const rsaEncryptKeyStaging = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC9PDXAzePK3HpWwKY5AyB/RoIr
g1+Pj6s7VvjXSQVAnNjMJ5QI5JApiWy+Nd9NjEo65AZtS8524coh9/QMEjFEdQ8O
6IRNhetwHfC2fkUBLp0BrQghmGowhln5W/4gUL3eEny9QBF388Y77FwB3QMW/b9V
JnARI6lpxuN6Q5DD+QIDAQAB
-----END PUBLIC KEY-----`;

export const rsaEncryptKeyProd = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDLWUy4H2VeltST774fTxE+oVjk
HPDIOmkOCt2Lx5lZXOJFO6cnvaZ5g2LkuHK3wJzpoYoCSf+utQcbUK8HBCrsqgeT
tPcHKeHJb+8RVr43IAutZ+V/p6xZjpy1KUSLaNCC7Yco7onVvlJgwhceU9qnKrYQ
es5a6zhR/azIqpmKZwIDAQAB
-----END PUBLIC KEY-----`;