import { RsaEncryptedParam } from "../../decorators/rsa.decorators";

export class LoginDto {

    @RsaEncryptedParam() userName: string = "";
    @RsaEncryptedParam() password: string = "";
    shopifyAccessToken: string;

    constructor(userName: string, password: string, shopifyAccessToken: string) {
        this.userName = userName;
        this.password = password;
        this.shopifyAccessToken = shopifyAccessToken;
    }
}