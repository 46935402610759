import React from 'react';
import ReactDOM from 'react-dom';
import { AppProvider } from "@shopify/polaris";
import { Provider as StoreProvider } from "react-redux";
import enTranslations from '@shopify/polaris/locales/en.json';
import { createStore, applyMiddleware, compose } from "redux";

import './index.css';
import App from './App';
import reducer from './store/reducers';

const composeEnhancers = (process.env.NODE_ENV === 'development' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose);
const store = createStore(reducer, composeEnhancers(applyMiddleware()));

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <AppProvider i18n={enTranslations}>
        <App />
      </AppProvider>
    </StoreProvider>,
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
